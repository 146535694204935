import React, { memo, useContext } from 'react';
import Joyride from 'react-joyride';
import './Guide.scss';
import Button from '@material-ui/core/Button';
import AppContext from '../../AppContext';
import { getShouldGuideStepSearch, getShouldAddWatchLaterStep, getShouldAddSeriesStep, getShouldAddShareStep } from '../../AppSelectors';

const LocalStorageKeySearchStep = "LOCAL_STORAGE_SEARCH_STEP";
const LocalStorageKeyWatchLaterStep = "LOCAL_STORAGE_WATCH_LATER_STEP";
const LocalStorageKeySeriesStep = "LOCAL_STORAGE_SERIES_STEP";
const LocalStorageKeyShareStep = "LOCAL_STORAGE_SHARE_STEP";

const Guide = memo(({ 
    shouldAddSearchStep,
    shouldAddWatchLaterStep,
    shouldAddSeriesStep,
    shouldAddShareStep
}) => {
    const steps = [];

    if (shouldAddSearchStep) {
        steps.push({
            target: '.search',
            content: 'Search for your favorite movie to get started',
            disableBeacon: true,
            localStorageKey: LocalStorageKeySearchStep
        });
    }

    if (shouldAddWatchLaterStep) {
        steps.push({
            target: '.list-type-picker',
            content: 'You can change this filter to save movies to watch later',
            disableBeacon: true,
            localStorageKey: LocalStorageKeyWatchLaterStep
        });
    }
    
    if (shouldAddSeriesStep) {
        steps.push({
            target: '.series-tab-header',
            content: 'TV Series Favorites and Watch Later lists are here!',
            disableBeacon: true,
            localStorageKey: LocalStorageKeySeriesStep
        });
    }
    
    if (shouldAddShareStep) {
        steps.push({
            target: '.share-button',
            content: "Sharing your list is even easier now, have a look!",
            disableBeacon: true,
            localStorageKey: LocalStorageKeyShareStep
        });
    }

    const Tooltip = ({
        continuous,
        index,
        step,
        backProps,
        closeProps,
        primaryProps,
        tooltipProps
    }) => (
        <div {...tooltipProps} className='guide-tooltip'>
            {step.title && <h1>{step.title}</h1>}
            <div className='content'>{step.content}</div>
            <div className='footer'>
                {index > 0 && (
                    <Button {...backProps} className='action-button' variant="contained" color="primary">
                        Back
                    </Button>
                )}
                {continuous && (
                    <Button {...primaryProps} className='action-button' variant="contained" color="primary">
                        Next
                    </Button>
                )}
                {!continuous && (
                    <Button {...closeProps} className='action-button' variant="contained" color="primary">
                        Got it
                    </Button>
                )}
            </div>
        </div>
    );

    const callback = ({ action, step }) => {
        if ((action === 'close') && step) {
            localStorage.setItem(step.localStorageKey, 'true');
        }
    }

    const filteredSteps = steps.filter(s => !localStorage.getItem(s.localStorageKey));
    const key = filteredSteps.reduce((accumulator, step) => accumulator += step.localStorageKey, '');
    
    return (
        <Joyride
            key={key}
            steps={filteredSteps}
            tooltipComponent={Tooltip}
            callback={callback}
            disableScrollParentFix={true}
            disableScrolling={true}
        />  
    );
});

export default function ConnectedGuide() {
    const { state } = useContext(AppContext);

    const shouldAddSearchStep = getShouldGuideStepSearch(state);
    const shouldAddWatchLaterStep = getShouldAddWatchLaterStep(state);
    const shouldAddSeriesStep = getShouldAddSeriesStep(state);
    const shouldAddShareStep = getShouldAddShareStep(state);

    return (
        <Guide
            shouldAddSearchStep={shouldAddSearchStep}
            shouldAddWatchLaterStep={shouldAddWatchLaterStep}
            shouldAddSeriesStep={shouldAddSeriesStep}
            shouldAddShareStep={shouldAddShareStep}
        />
    );
}