import { LIST_TYPE, MEDIA_TYPE } from "../../../Constants"

export const getListTypeName = (listType) => {
    return listType === LIST_TYPE.Favorites
        ? 'Favorites'
        : 'Watch Later';
}

export const getMediaTypeName = (mediaType) => {
    return mediaType === MEDIA_TYPE.Movies
        ? 'Movies'
        : 'Series';
}

export const getOtherListType = (listType) => {
    return listType === LIST_TYPE.Favorites
        ? LIST_TYPE.WatchLater
        : LIST_TYPE.Favorites;
}

export const getOtherListTypeName = (listType) => {
    return getListTypeName(getOtherListType(listType));
}

export const getDroppableId = (mediaType, listType) => {
    return `${mediaType}_${listType}`;
}