import { useContext, memo, useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './Home.scss';
import { BASE_URL, EVENTS, MAX_NUM_MOVIES, MEDIA_TYPE, ROUTES } from '../../Constants';
import AppContext from '../../AppContext';
import Profile from './Profile';
import { FirebaseContext, log } from '../../Firebase';
import Charts from './Charts';
import { DragDropContext } from 'react-beautiful-dnd';
import { getIsSignedIn } from '../../AppSelectors';
import SearchBar from './SearchBar';
import { profileHeight } from '../../StyleExports.module.scss';
import { EmailVerification } from './EmailVerification';
import { getIsOnline } from '../../Utilities/EnvironmentUtilities';
import { dateDiffInDays } from '../../Utilities/CommonUtilities';
import MediaTypeTabs from './MediaTypeTabs';
import ConnectedListWrapper from './ListWrapper';
import { smallScreenMax } from '../../StyleExports.module.scss';
import Suggestions from './Suggestions';

const Home = memo(({ 
    isSignedIn,
    isOnline,
    hasSentEmailVerification, 
    setHasSentEmailVerification,
    reorderList,
    shouldShowSuggestions,
    currentMediaType
}) => {
    const history = useHistory();
    const firebase = useContext(FirebaseContext);
    const topTilesRef = useRef(null);
    const searchRef = useRef(null);
    const [isScrolledThreshold, setIsScrolledThreshold] = useState(false);
    
    // Send back to login page if not signed in
    useEffect(() => {
        if (!isSignedIn) {
            history.push(ROUTES.Login);
        }
    }, [isSignedIn, history]);

    // Pin search bar to top of mobile when scrolled past upper profile
    useEffect(() => {
        const onScroll = () => {
            const threshold = parseInt(profileHeight) - 5;
    
            if (!isScrolledThreshold && (window.scrollY >= threshold)) {
                setIsScrolledThreshold(true);
            } else if (isScrolledThreshold && (window.scrollY < threshold)) {
                setIsScrolledThreshold(false);
            }
        }

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [isScrolledThreshold, setIsScrolledThreshold]);

    //#region Email confirmation
    const sendConfirmationEmail = useCallback(() => {
        const authUser = firebase.auth().currentUser;

        authUser.sendEmailVerification({ url: BASE_URL })
            .catch((err) => {
                console.error(err)
            });
    }, [firebase]);

    const onClickSendEmail = () => {
        log(EVENTS.ResendEmail);
        sendConfirmationEmail();
    }

    useEffect(() => {
        const authUser = firebase.auth().currentUser;
        if (authUser && !authUser.emailVerified && !hasSentEmailVerification && isOnline) {
            sendConfirmationEmail();
            setHasSentEmailVerification(true);
        }
    }, [firebase, hasSentEmailVerification, setHasSentEmailVerification, sendConfirmationEmail, isOnline]);
    //#endregion

    if (!isSignedIn) {
        return null;
    }

    const authUser = firebase.auth().currentUser;
    if (authUser && authUser.email && !authUser.emailVerified) {
        const dayCreated = new Date(authUser.metadata.creationTime);
        const today = new Date();
        const daysSinceCreated = dateDiffInDays(dayCreated, today);

        if (daysSinceCreated >= 1) {
            return <EmailVerification 
                email={authUser.email}
                onClickSendEmail={onClickSendEmail}
                isOnline={isOnline}
            />;
        }
    }

    const onDragStart = () => {
        searchRef.current?.blur();
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const [mediaType, listType] = (result.destination.droppableId.split('_'));    
        reorderList(result, mediaType, listType);
    }

    return (
        <div className={`home-page ${isScrolledThreshold ? 'threshold' : ''}`}>
            <div className='upper'>
                <Profile ref={topTilesRef} />
                {shouldShowSuggestions && <Suggestions key={currentMediaType} />}
            </div>
            <div className='lower'>
                <div className='left'>
                    <div className='sticky-header'>
                        <MediaTypeTabs initialTabIndex={currentMediaType === MEDIA_TYPE.Series ? 1 : 0} />
                        <SearchBar ref={searchRef} />
                    </div>
                    <DragDropContext 
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                    >
                        <ConnectedListWrapper ref={topTilesRef} isScrolledThreshold={isScrolledThreshold} />
                    </DragDropContext>
                </div>
                <div className='right'>
                    <Charts />
                </div>
            </div>
        </div>
    )
});

export default function ConnectedHome() {
    const { state, actions } = useContext(AppContext);
    const { hasSentEmailVerification, friends, favoriteMovies, favoriteSeries, currentMediaType } = state;
    const { setHasSentEmailVerification, reorderList } = actions;

    const isSignedIn = getIsSignedIn(state);
    const isOnline = getIsOnline();

    const favoriteItems = currentMediaType === MEDIA_TYPE.Movies
        ? favoriteMovies
        : favoriteSeries;

    const shouldShowSuggestions = window.innerWidth > parseInt(smallScreenMax)
        && (friends.length > 0) 
        && (favoriteItems.length < MAX_NUM_MOVIES);

    return (
        <Home 
            isSignedIn={isSignedIn}
            isOnline={isOnline}
            hasSentEmailVerification={hasSentEmailVerification}
            setHasSentEmailVerification={setHasSentEmailVerification} 
            reorderList={reorderList}
            shouldShowSuggestions={shouldShowSuggestions}
            currentMediaType={currentMediaType}
        />
    );
}