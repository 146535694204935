import { forwardRef, memo, useRef } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useContext } from 'react';
import AppContext from '../../../AppContext';
import MovieTile from '../../../CommonComponents/MovieTile';
import { LIST_TYPE, MAX_NUM_MOVIES, MEDIA_TYPE, NUM_TOP_TILES } from '../../../Constants';
import { useScrollToBottom, useScrollToTop } from '../../../Utilities/Hooks';
import CustomMenu from '../../../CommonComponents/CustomMenu';
import { getIsOnline } from '../../../Utilities/EnvironmentUtilities';
import { getDroppableId, getListTypeName, getMediaTypeName, getOtherListType, getOtherListTypeName } from './ListWrapperUtilities';
import LogoScreenshot from '../../../Resources/Images/logoScreenshot.png';
import AvatarIcon from '../../../Resources/Images/defaultAvatar.png';

const ListWrapper = memo(forwardRef(({ 
    mediaType,
    listType,
    listTiles,
    removeTileFromList,
    addItemToList,
    canMoveToOtherListType,
    isScrolledThreshold,
    isOnline,
    profilePicUrl
}, topTilesRef) => {
    const containerRef = useRef(null);

    useScrollToTop(mediaType, listType, containerRef);
    useScrollToBottom(mediaType, listType, isScrolledThreshold, listTiles, containerRef);

    const menuOptions = [
        { 
            label: <span>Move to <b>{getOtherListTypeName(listType)}</b></span>, 
            onClick: (tile, index) => {
                removeTileFromList(tile.imdbID, index, mediaType, listType);
                addItemToList(tile, mediaType, getOtherListType(listType), listType);
            }, 
            isDisabled: !canMoveToOtherListType || !isOnline
        },
        { 
            label: 'Remove', 
            onClick: (tile, index) => {
                removeTileFromList(tile.imdbID, index, mediaType, listType);
            },
            isDisabled: !isOnline
        }
    ];

    const className = listType === LIST_TYPE.Favorites
        ? 'favorite-list'
        : 'watch-later-list';

    const renderTile = (tile, index, key) => (
        <Draggable 
            key={key} 
            draggableId={`draggable-favorite-${tile.imdbID}`} 
            index={index} 
            isDragDisabled={!isOnline}
        >
            {(provided) => (
                <div
                    className='tile-wrapper'
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <MovieTile 
                        dragHandleProps={provided.dragHandleProps}
                        rank={listType === LIST_TYPE.Favorites ? index+1 : undefined}
                        movie={tile}
                        mediaType={mediaType}
                        listType={listType}
                    >
                        <CustomMenu 
                            menuOptions={menuOptions}
                            movie={tile}
                            index={index} 
                        />
                    </MovieTile>
                </div>
            )}
        </Draggable>
    );

    // Image to be saved on share
    const hiddenTopTiles = (
        <div className='top-tiles hidden' ref={topTilesRef}>
            <div className='top-tiles-header'>
                <img className='logo-screenshot' src={LogoScreenshot} alt='WYL Logo' />
                <img className='top-tiles-profile-pic' src={profilePicUrl ?? AvatarIcon} alt='Profile pic' />
            </div>
            {listTiles.slice(0, NUM_TOP_TILES).map((t, i) => <div key={`top_${t.imdbID}`}>
                {renderTile(t, i, undefined)}
                <div key={`spacer_${t.imdbID}`} className='spacer' />
            </div>)}
            <div className='top-tiles-footer'>whatsyourlist.com</div>
        </div>
    );

    const emptyListPlaceholder = (
        <div className='empty-list-placeholder'>
            Use the search bar to add some 
            <b> {getMediaTypeName(mediaType)} </b> 
            to your 
            <b> {getListTypeName(listType)} </b>
            list
        </div>
    );

    return (
        <div className={`list-wrapper ${className}`} ref={containerRef}>
            <Droppable droppableId={getDroppableId(mediaType, listType)}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}>
                        {hiddenTopTiles}
                        {listTiles.map((t, i) => renderTile(t, i, t.imdbID))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>  
            {listTiles.length === 0 && emptyListPlaceholder}
        </div>         
    );
}));

const ConnectedListWrapper = forwardRef((props, topTilesRef) => { 
    const { state, actions } = useContext(AppContext);
    const { 
        currentMediaType,
        currentListType,
        favoriteMovies = [],
        watchLaterMovies = [],
        favoriteSeries = [],
        watchLaterSeries = [],
        publicUserInfo,
    } = state;
    const { removeItemFromList, addItemToList } = actions;

    let listTiles, canMoveToOtherListType;
    if (currentMediaType === MEDIA_TYPE.Movies) {
        if (currentListType === LIST_TYPE.Favorites) {
            listTiles = favoriteMovies;
            canMoveToOtherListType = watchLaterMovies.length < MAX_NUM_MOVIES;
        } else {
            listTiles = watchLaterMovies;
            canMoveToOtherListType = favoriteMovies.length < MAX_NUM_MOVIES;
        }
    } else {
        if (currentListType === LIST_TYPE.Favorites) {
            listTiles = favoriteSeries;
            canMoveToOtherListType = watchLaterSeries.length < MAX_NUM_MOVIES;
        } else {
            listTiles = watchLaterSeries;
            canMoveToOtherListType = favoriteSeries.length < MAX_NUM_MOVIES;
        }
    }

    const isOnline = getIsOnline();

    return (
        <ListWrapper 
            {...props}
            ref={topTilesRef}
            mediaType={currentMediaType}
            listType={currentListType}
            listTiles={listTiles}
            removeTileFromList={removeItemFromList}
            addItemToList={addItemToList}
            canMoveToOtherListType={canMoveToOtherListType}
            isOnline={isOnline}
            profilePicUrl={publicUserInfo?.profilePicUrl}
        />
    );
});

export default ConnectedListWrapper;