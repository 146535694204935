import { LIST_TYPE, MEDIA_TYPE } from "../Constants";

export const getFirstAndLastName = (fullName) => {
    const firstName = fullName.substr(0, fullName.indexOf(' '));
    const lastName = fullName.substr(fullName.indexOf(' ')+1);

    if (!firstName || !firstName.length) {
        return [lastName, null];
    }

    return [firstName, lastName]
}

// Choose state and db property name (matches in both)
export const getCollectionName = (mediaType, listType) => {
    if (mediaType === MEDIA_TYPE.Movies) {
        return listType === LIST_TYPE.Favorites
            ? 'favoriteMovies'
            : 'watchLaterMovies';
    } else {
        return listType === LIST_TYPE.Favorites
            ? 'favoriteSeries'
            : 'watchLaterSeries';
    }
}

// Get diff in days (can be positive OR negative)
export function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function sortAlphabetically(a, b) {
    if (a < b){
        return -1;
    }
    if (a > b){
        return 1;
    }
    
    return 0;
}