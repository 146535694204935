import { ANONYMOUS_USER_ID } from "./Constants";

export function getIsSignedIn(state) {
    const uid = state.user?.uid;

    return !!uid && (uid !== ANONYMOUS_USER_ID);
}

// This will still return true for anonymous users
export function getHasAttemptedSignIn(state) {
    return !!state.user?.uid;
}

export function getShouldGuideStepSearch({ 
    isSearchMounted, 
    favoriteMovies 
}) {
    return isSearchMounted && !favoriteMovies.length;
}

export function getShouldAddWatchLaterStep({ 
    isSearchFilterMounted, 
    favoriteMovies,
    watchLaterMovies 
}) {
    return isSearchFilterMounted && favoriteMovies.length && !watchLaterMovies.length;
}

export function getShouldAddSeriesStep({ 
    isSeriesTabHeaderMounted,
    favoriteMovies,
    favoriteSeries
}) {
    return isSeriesTabHeaderMounted && favoriteMovies.length && !favoriteSeries.length;
}

export function getShouldAddShareStep({ 
    isShareButtonMounted,
    favoriteMovies,
    favoriteSeries
}) {
    return isShareButtonMounted && (favoriteMovies.length >= 5 || favoriteSeries.length >= 5);
}