import React, { memo, useContext } from "react";
import AppContext from "../../AppContext";
import { LIST_TYPE, MAX_NUM_MOVIES, MEDIA_TYPE } from "../../Constants";
import MovieTile from "../../CommonComponents/MovieTile";
import Chip from '../../CommonComponents/Chip';
import CustomMenu from "../../CommonComponents/CustomMenu";
import { getIsSignedIn } from "../../AppSelectors";

const ViewListFavorites = memo(({ 
    isSignedIn, 
    currentMediaType,
    viewListItems, 
    favoriteItems, 
    watchLaterItems,
    addItemToList 
}) => {
    const favoriteIds = favoriteItems.map(m => m.imdbID);
    const watchLaterIds = watchLaterItems.map(m => m.imdbID);

    const getTileActionItem = (movie, index) => {
        if (!isSignedIn) { return null }

        const { imdbID } = movie;

        const alreadyInFavorites = favoriteIds?.some(id => id === imdbID);
        const alreadySavedForLater = watchLaterIds?.some(id => id === imdbID);

        if (alreadyInFavorites || alreadySavedForLater) {
            return (
                <Chip 
                    favoriteIds={favoriteIds}
                    watchLaterIds={watchLaterIds}
                    imdbID={imdbID}
                />
            )
        }

        const menuOptions = [
            { 
                label: <span>Add to <b>Favorites</b></span>, 
                onClick: (item) => {
                    addItemToList(item, currentMediaType, LIST_TYPE.Favorites, 'View Profile List');
                }, 
                isDisabled: favoriteIds.length >= MAX_NUM_MOVIES
            },
            { 
                label: <span>Add to <b>Watch Later</b></span>, 
                onClick: (item) => {
                    addItemToList(item, currentMediaType, LIST_TYPE.WatchLater, 'View Profile List');
                },
                isDisabled: watchLaterIds.length >= MAX_NUM_MOVIES
            }
        ];

        return (
            <CustomMenu 
                menuOptions={menuOptions}
                menuButtonText='Add'
                movie={movie}
                index={index} 
            />
        );
    }
    
    return (
        <div className='movie-list view-list-favorite-movies'>
            {viewListItems && viewListItems.map((movie, i) => (
                <MovieTile key={movie.imdbID} rank={i+1} movie={movie}>
                    {getTileActionItem(movie, i)}
                </MovieTile>
            ))}
        </div>
    );
});

export default function ConnectedViewListFavorites(props) {
    const { state, actions } = useContext(AppContext);

    const isSignedIn = getIsSignedIn(state);
    const { currentMediaType, favoriteMovies, watchLaterMovies, favoriteSeries, watchLaterSeries } = state;

    let favoriteItems = [], watchLaterItems = [];
    if (isSignedIn) {
        if (currentMediaType === MEDIA_TYPE.Movies) {
            favoriteItems = favoriteMovies;
            watchLaterItems = watchLaterMovies;
        } else {
            favoriteItems = favoriteSeries;
            watchLaterItems = watchLaterSeries;
        }
    }

    const { addItemToList } = actions;

    return (
        <ViewListFavorites 
            {...props} 
            isSignedIn={isSignedIn}
            currentMediaType={currentMediaType}
            favoriteItems={favoriteItems} 
            watchLaterItems={watchLaterItems}
            addItemToList={addItemToList} 
        />
    );
}