import React, { memo, useContext, useEffect } from 'react';
import AppContext from '../../../AppContext';
import { FavoriteListIcon, WatchLaterListIcon } from '../../../CommonComponents/Icons';
import { LIST_TYPE } from '../../../Constants';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const SearchFilter = memo(({
    currentListType,
    changeListType,
    setIsSearchFilterMounted
}) => {
    useEffect(() => {
        setIsSearchFilterMounted(true);

        return () => {
            setIsSearchFilterMounted(false);
        }
    }, [setIsSearchFilterMounted])

    const handleListTypeChange = (e) => {
        changeListType(e.target.value);
    }

    return (
        <Select
            className="list-type-picker"
            value={currentListType}
            onChange={handleListTypeChange}
            color="secondary"
            variant="outlined"
        >
            <MenuItem className="list-type-option" value={LIST_TYPE.Favorites}>
                {FavoriteListIcon}
                Favorites
            </MenuItem>
            <MenuItem className="list-type-option" value={LIST_TYPE.WatchLater}>
                {WatchLaterListIcon}
                Watch Later
            </MenuItem>
        </Select>
    );
});

export default function ConnectedSearchFilter() {
    const { state, actions } = useContext(AppContext);
    const { currentListType } = state;
    const { changeListType, setIsSearchFilterMounted } = actions;

    return (
        <SearchFilter 
            currentListType={currentListType}
            changeListType={changeListType}
            setIsSearchFilterMounted={setIsSearchFilterMounted}
        />
    );
}