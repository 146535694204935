import { createContext, PureComponent, memo } from 'react';
import { getItemMetadataApi } from './Utilities/ApiUtilities';
import { ANONYMOUS_USER_ID, EVENTS, LIST_TYPE, MEDIA_TYPE, ROUTES } from './Constants';
import { addItemToCollection, createAccount, getCollection, log, removeItemFromCollection, updateOrderIds } from './Firebase';
import uniqBy from 'lodash.uniqby';
import { getCollectionName, sortAlphabetically } from './Utilities/CommonUtilities';
import { withRouter } from 'react-router-dom';

const AppContext = createContext({});

const defaultState = {
    user: null,
    publicUserInfo: null,
    friends: [],
    friendRequests: [],
    favoriteMovies: [],
    watchLaterMovies: [],
    suggestedMovies: [],
    favoriteSeries: [],
    watchLaterSeries: [],
    suggestedSeries: [],
    hasSentEmailVerification: false,
    currentMediaType: MEDIA_TYPE.Movies,
    currentListType: LIST_TYPE.Favorites,
    toastMessage: null,
    // For use of react-joyride:
    isSearchMounted: false,
    isSearchFilterMounted: false,
    isSeriesTabHeaderMounted: false,
    isShareButtonMounted: false
};

class AppContextProviderInternal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = defaultState;
    }

    componentDidMount() {
        this.unsubscribeFromAuthState = this.props.firebase.auth().onAuthStateChanged(authUser => {
            this.setUser(authUser);
        })
    }

    componentWillUnmount() {
        this.unsubscribeFromAuthState();
    }

    setUser = async (user) => {
        if (!user) {
            this.setState({ user: {
                uid: ANONYMOUS_USER_ID,
                email: null
            }});

            return;
        }


        const db = this.props.firebase.firestore();

        // subscribe to user updates
        const userDoc = await db.collection('users').doc(user.uid).get();

        if (userDoc.exists) {
            // retrieve movie lists
            const favoriteMovies = await getCollection(user.uid, 'favoriteMovies');
            const watchLaterMovies = await getCollection(user.uid, 'watchLaterMovies');
            const favoriteSeries = await getCollection(user.uid, 'favoriteSeries');
            const watchLaterSeries = await getCollection(user.uid, 'watchLaterSeries');

            // set state
            this.setState({ 
                user: userDoc.data(), 
                favoriteMovies,
                watchLaterMovies,
                favoriteSeries,
                watchLaterSeries
            });
        } else {
            await createAccount(user, this.onErrorFirebase);

            const newUserDoc = await db.collection('users').doc(user.uid).get();
            
            this.setState({ user: newUserDoc.data() });
        }

        // subscribe to name and profile pic changes
        this.unsubscribeFromPublicUserInfo = db
            .collection('publicUserInfo')
            .doc(user.uid)
            .onSnapshot(querySnapshot => {
                querySnapshot && this.setState({ publicUserInfo: querySnapshot.data() });
            });

        // // subscribe to friend requests
        this.unsubscribeFromFriendRequests = db
            .collection('users')
            .doc(user.uid)
            .collection('friendRequests')
            .onSnapshot(querySnapshot => {
                const friendRequests = [...this.state.friendRequests];

                querySnapshot.docChanges().forEach(change => {
                    const changeType = change.type;
                    const changeDocId = change.doc.id;
                    const changeDocIndex = friendRequests.findIndex(r => r.uid === changeDocId);

                    if ((changeType === 'added') && (changeDocIndex === -1)) {
                        const { name, profilePicUrl } = change.doc.data();

                        const request = {
                            uid: change.doc.id,
                            name,
                            profilePicUrl
                        };

                        friendRequests.unshift(request);
                    } else if ((change.type === 'removed') && (changeDocIndex >= 0)) {
                        friendRequests.splice(changeDocIndex, 1);
                    }
                });
                
                this.setState({ friendRequests });
            });

        // subscribe to friends list (to show accepted requests in real-time)
        this.unsubscribeFromFriends = db.collection('users').doc(user.uid).collection('friends').onSnapshot(async querySnapshot => {
            const friends = [...this.state.friends];

            const changes = await querySnapshot.docChanges();
            for (const change of changes) {
                const changeType = change.type;
                const changeDocId = change.doc.id;
                const changeDocIndex = friends.findIndex(r => r.uid === changeDocId);

                if ((changeType === 'added') && (changeDocIndex === -1)) {
                    // Instead of storing name and profilePic on friend object, 
                    // we access it here to make sure it's up to date
                    const friendDoc = await db.collection('publicUserInfo').doc(change.doc.id).get();
                    const { name, profilePicUrl } = friendDoc.data();

                    const friend = { 
                        uid: change.doc.id,
                        name,
                        profilePicUrl
                    };

                    friends.unshift(friend);
                } else if ((change.type === 'removed') && (changeDocIndex >= 0)) {
                    friends.splice(changeDocIndex, 1);
                }
            }

            friends.sort((a, b) => sortAlphabetically(a.name.toLowerCase(), b.name.toLowerCase()));
            
            this.setState({ friends });
        });
    }

    signOut = () => {
        this.unsubscribeFromPublicUserInfo && this.unsubscribeFromPublicUserInfo();
        this.unsubscribeFromFriendRequests && this.unsubscribeFromFriendRequests();
        this.unsubscribeFromFriends && this.unsubscribeFromFriends();

        this.unsubscribeFromPublicUserInfo = null;
        this.unsubscribeFromFriendRequests = null;
        this.unsubscribeFromFriends = null;

        this.props.firebase.auth().signOut().then(() => {
            this.setState(defaultState, () => {
                this.props.history.push(ROUTES.Login);
            });
        });
    }

    setHasSentEmailVerification = (hasSentEmailVerification) => {
        this.setState({ hasSentEmailVerification });
    }

    reorderList = (result, mediaType, listType) => {
        const listName = getCollectionName(mediaType, listType);
        const listToUse = this.state[listName];

        const items = [...listToUse];
        const [reorderedItem] = items.splice(result.source.index, 1);
    
        items.splice(result.destination.index, 0, reorderedItem);
        const newListWithUpdatedOrderIds = items.map((item, i) => ({ ...item, OrderId: i }));

        this.setState({ [listName]: newListWithUpdatedOrderIds });

        updateOrderIds(this.state.user.uid, newListWithUpdatedOrderIds, listName, this.onErrorFirebase);
    }

    setSuggestedItems = (suggestedItems, mediaType) => {
        const listName = mediaType === MEDIA_TYPE.Movies
            ? 'suggestedMovies'
            : 'suggestedSeries';

        this.setState({ [listName]: suggestedItems });
    }

    setToastMessage = (item, listType) => {
        const { Title, Year } = item;
        const itemName = `${Title} (${Year})`;

        const toastMessage = listType === LIST_TYPE.Favorites
            ? `Added ${itemName} to Favorites`
            : `Added ${itemName} to Watch Later`;

        this.setState({ toastMessage });
    }

    setGenericToastMessage = (toastMessage) => {
        this.setState({ toastMessage });
    }

    addItemToList = async (item, mediaType, listType, logSource) => {
        const { imdbID, Title, Year, Poster, Genre } = item;

        log(EVENTS.AddMovie, { 
            source: logSource,
            imdbID,
            mediaType,
            listType 
        });

        // Choose state and db property name (matches in both)
        const listName = getCollectionName(mediaType, listType);

        // Set OrderId to bottom of list or specified override
        const OrderId = this.state[listName].length;

        // If the movie doesn't have genre metadata, retrieve it
        let genreString = Genre;
        if (!genreString) {
            const Metadata = await getItemMetadataApi(imdbID, mediaType);
            genreString = Metadata.Genre;
        }

        // Construct item object
        const Genres = genreString.split(", ");
        const itemToAdd = { imdbID, Title, Year, Poster, OrderId, Genres };

        const suggestedListName = mediaType === MEDIA_TYPE.Movies
            ? 'suggestedMovies'
            : 'suggestedSeries';

        // If it was in the list of suggested items, remove it
        const suggestedIndex = this.state[suggestedListName].findIndex(m => m.imdbID === imdbID);
        if (suggestedIndex > -1) {
            const newSuggestedItems = [...this.state[suggestedListName]];
            newSuggestedItems.splice(suggestedIndex, 1);

            this.setState({ [suggestedListName]: newSuggestedItems });
        }

        // Construct new list
        const newItemsList = [...this.state[listName], itemToAdd];
        const uniqueMoviesList = uniqBy(newItemsList, 'imdbID');
        
        // Add to state and db
        this.setState({ [listName]: uniqueMoviesList });
        addItemToCollection(this.state.user.uid, listName, itemToAdd, this.onErrorFirebase);

        // Notify user
        this.setToastMessage(itemToAdd, listType);
    }

    removeItemFromList = async (imdbID, indexToRemove, mediaType, listType) => {
        log(EVENTS.RemoveMovie);

        const listName = getCollectionName(mediaType, listType);

        const newList = [...this.state[listName]];
        newList.splice(indexToRemove, 1);

        const newListWithUpdatedOrderIds = newList.map((item, i) => ({ ...item, OrderId: i }));

        this.setState({ [listName]: newListWithUpdatedOrderIds });

        await removeItemFromCollection(this.state.user.uid, listName, imdbID, this.onErrorFirebase);

        updateOrderIds(this.state.user.uid, newListWithUpdatedOrderIds, listName, this.onErrorFirebase);
    }

    // If a poster doesn't load for a user, make an attempt to replace it
    replacePoster = async (movie, mediaType, listType) => {
        const { Poster } = await getItemMetadataApi(movie.imdbID);

        const movieToReplace = { ...movie, Poster };
        const listName = getCollectionName(mediaType, listType);

        // replace in db
        addItemToCollection(
            this.state.user.uid, 
            listName, 
            movieToReplace,
            this.onErrorFirebase
        );

        // replace in state
        const newList = this.state[listName].map(m => {
            if (m.imdbID === movieToReplace.imdbID) {
                return movieToReplace;
            }

            return m;
        });

        this.setState({ [listName]: newList });
        
    }

    addFriend = (id, logSource) => {
        log(EVENTS.AddFriend, {
            source: logSource
        });

        this.props.firebase.firestore()
            .collection('users')
            .doc(id)
            .collection('friendRequests')
            .doc(this.state.user.uid)
            .set({ 
                name: this.state.publicUserInfo.name,
                profilePicUrl: this.state.publicUserInfo.profilePicUrl
            });
    }

    removeFriend = async (id) => {
        log(EVENTS.RemoveFriend);

        await this.props.firebase.firestore()
            .collection('users')
            .doc(this.state.user.uid)
            .collection('friends')
            .doc(id)
            .delete();

        await this.props.firebase.firestore()
            .collection('users')
            .doc(id)
            .collection('friends')
            .doc(this.state.user.uid)
            .delete();

        const newFriends = this.state.friends.filter(f => f.uid !== id);

        this.setState({ friends: newFriends });
    }

    acceptFriendRequest = async (friend) => {
        log(EVENTS.AcceptRequest);

        await this.props.firebase.firestore()
            .collection('users')
            .doc(this.state.user.uid)
            .collection('friends')
            .doc(friend.uid)
            .set({});

        await this.props.firebase.firestore()
            .collection('users')
            .doc(friend.uid)
            .collection('friends')
            .doc(this.state.user.uid)
            .set({});

        this.deleteFriendRequest(friend.uid);
    }

    deleteFriendRequest = (id) => {
        log(EVENTS.DeleteRequest, { id });

        this.props.firebase.firestore()
            .collection('users')
            .doc(this.state.user.uid)
            .collection('friendRequests')
            .doc(id)
            .delete().then(() => {
                const indexInState = this.state.friendRequests.findIndex(r => r.uid === id);
                const newList = [...this.state.friendRequests];

                newList.splice(indexInState, 1);

                this.setState({ friendRequests: newList });
            });
    }

    changeMediaType = (currentMediaType) => {
        this.setState({ currentMediaType, currentListType: LIST_TYPE.Favorites });
    }

    changeListType = (currentListType) => {
        this.setState({ currentListType });
    }
    
    setIsSearchMounted = (isSearchMounted) => {
        this.setState({ isSearchMounted });
    }

    setIsSearchFilterMounted = (isSearchFilterMounted) => {
        this.setState({ isSearchFilterMounted });
    }

    setIsSeriesTabHeaderMounted = (isSeriesTabHeaderMounted) => {
        this.setState({ isSeriesTabHeaderMounted });
    }

    setIsShareButtonMounted = (isShareButtonMounted) => {
        this.setState({ isShareButtonMounted });
    }

    onErrorFirebase = () => {
        this.props.history.go(0);
    }

    render() {
        const contextValue = {
            state: this.state,
            actions: {
                signOut: this.signOut,
                addItemToList: this.addItemToList,
                reorderList: this.reorderList,
                removeItemFromList: this.removeItemFromList,
                replacePoster: this.replacePoster,
                setHasSentEmailVerification: this.setHasSentEmailVerification,
                addFriend: this.addFriend,
                acceptFriendRequest: this.acceptFriendRequest,
                deleteFriendRequest: this.deleteFriendRequest,
                removeFriend: this.removeFriend,
                changeMediaType: this.changeMediaType,
                changeListType: this.changeListType,
                setSuggestedItems: this.setSuggestedItems,
                setGenericToastMessage: this.setGenericToastMessage,
                setIsSearchMounted: this.setIsSearchMounted,
                setIsSearchFilterMounted: this.setIsSearchFilterMounted,
                setIsSeriesTabHeaderMounted: this.setIsSeriesTabHeaderMounted,
                setIsShareButtonMounted: this.setIsShareButtonMounted
            }
        };

        return (
            <AppContext.Provider value={contextValue}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export const AppContextProvider = withRouter(memo(AppContextProviderInternal));
export default AppContext