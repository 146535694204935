import ViewListProfile from './ViewListProfile';
import { useEffect, useContext, useState, memo } from 'react';
import { FirebaseContext } from '../../Firebase';
import ViewListFavorites from './ViewListFavorites';
import Charts from '../Home/Charts';
import AppContext from '../../AppContext';
import { useHistory } from 'react-router';
import { MEDIA_TYPE, ROUTES } from '../../Constants';
import './ViewList.scss';
import MediaTypeTabs from '../Home/MediaTypeTabs';

const ViewList = memo(({ uid, mediaType, changeMediaType }) => {
    const history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const viewId = urlParams.get('id');
    const initialMediaType = urlParams.get('type');
    const firebase = useContext(FirebaseContext);
    const [profilePicUrl, setProfilePicUrl] = useState(null);
    const [name, setName] = useState(null);
    const [viewListMovies, setViewListMovies] = useState(null);
    const [viewListSeries, setViewListSeries] = useState(null);

    useEffect(() => {
        if (uid && (viewId === uid)) {
            history.push(ROUTES.Home);
        }

        if (initialMediaType === 'series') {
            changeMediaType(MEDIA_TYPE.Series);
        }

        if (viewId) {
            firebase.firestore()
                .collection('publicUserInfo')
                .doc(viewId)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        const publicUserInfo = doc.data();

                        setProfilePicUrl(publicUserInfo.profilePicUrl);
                        setName(publicUserInfo.name);

                        firebase.firestore()
                            .collection('publicUserInfo')
                            .doc(viewId)
                            .collection('favoriteMovies')
                            .orderBy('OrderId')
                            .get()
                            .then(snapshot => {
                                const movies = snapshot.docs.map(doc => doc.data());

                                setViewListMovies(movies);
                            });

                        firebase.firestore()
                            .collection('publicUserInfo')
                            .doc(viewId)
                            .collection('favoriteSeries')
                            .orderBy('OrderId')
                            .get()
                            .then(snapshot => {
                                const series = snapshot.docs.map(doc => doc.data());

                                setViewListSeries(series);
                            });
                    }
                });
        }
    }, [viewId, firebase, history, uid, changeMediaType, initialMediaType]);

    const currentViewList = mediaType === MEDIA_TYPE.Movies
        ? viewListMovies
        : viewListSeries;

    const shouldShowCharts = !!currentViewList && (currentViewList.length > 0);

    return (
        <div className='view-list-page'>
            <div className='upper'>
                <ViewListProfile profilePicUrl={profilePicUrl} name={name} viewId={viewId} />
            </div>
            <div className='lower'>
                <div className='left'>
                    <MediaTypeTabs initialTabIndex={initialMediaType === 'series' ? 1 : 0} />
                    <div className='view-list-container'>
                        <ViewListFavorites viewListItems={currentViewList} />
                    </div>
                </div>
                <div className='right'>
                    {shouldShowCharts && <Charts viewListItems={currentViewList} />}
                </div>
            </div>
        </div>
    )
});

export default function ConnectedViewList() {
    const { state, actions } = useContext(AppContext);
    const { user, currentMediaType } = state;
    const { changeMediaType } = actions;
    const uid = user && user.uid;

    return <ViewList 
        uid={uid} 
        mediaType={currentMediaType}
        changeMediaType={changeMediaType} 
    />;
}