import Button from '@material-ui/core/Button';
import { useState, useEffect, memo, forwardRef } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useContext } from 'react';
import AppContext from '../../../AppContext';
import { downloadToFile } from './ShareUtilities';
import Modal from '../../../CommonComponents/Modal';
import './ShareModal.scss';
import { TextField } from '@material-ui/core';
import { BASE_URL, MEDIA_TYPE } from '../../../Constants';
import IosShareIcon from '../../../Resources/Icons/IosShareIcon';
import { getIsOnline } from '../../../Utilities/EnvironmentUtilities';
import { toBlob, toPng } from 'html-to-image';
import download from 'downloadjs';

/* eslint-disable */
const ShareButton = memo(forwardRef(({ 
    favoriteItems, 
    uid, 
    isDisabled, 
    type, 
    setIsShareButtonMounted, 
    setGenericToastMessage 
}, topTilesRef) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSharing, setIsSharing] = useState(false);

    const isDisabledOrSharing = isDisabled || isSharing;

    const shareLink = `${BASE_URL}/viewList?id=${uid}&type=${type}`;

    const filter = (node) => {
        const exclusionClasses = ['MuiSvgIcon-root', 'custom-menu'];
        return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    }

    useEffect(() => {
        setIsShareButtonMounted(true);

        return () => {
            setIsShareButtonMounted(false);
        }
    }, [setIsShareButtonMounted]);

    useEffect(() => {
        if (isModalOpen) {
            navigator.clipboard.writeText(shareLink);
        }
    }, [isModalOpen, shareLink]);

    const handleShare = async (event) => {
        if (!navigator?.canShare) {
            setAnchorEl(event.currentTarget)

            return;
        }

        setIsSharing(true);

        try {
            navigator.clipboard.writeText(shareLink);
            setGenericToastMessage('Copied link to clipboard');

            topTilesRef.current.classList.add('show');

            // For some crazy reason it only renders this properly on the third file creation.
            const newFile = await toBlob(topTilesRef.current, { 
                filter,
                backgroundColor: 'black'
            });

            const newFile2 = await toBlob(topTilesRef.current, { 
                filter,
                backgroundColor: 'black'
            });

            const newFile3 = await toBlob(topTilesRef.current, { 
                filter,
                backgroundColor: 'black'
            });

            topTilesRef.current.classList.remove('show');

            const data = {
                files: [
                    new File([newFile3], 'whatsyourlist.png', {
                        type: newFile3.type,
                    }),
                ],
                title: "Whats Your List?",
                text: `Check out my favorite ${type} from WhatsYourList.com!\n`,
                url: shareLink
            };

            if (!navigator?.canShare(data)) {
                console.error('Cant use Web Share API');
            }

            await navigator.share(data);

            setIsSharing(false);
        } catch (err) {
            setIsSharing(false);
            console.error(err);

            setAnchorEl(event.currentTarget);
        }
    }
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSaveImage = async () => {
        setIsSharing(true);

        topTilesRef.current.classList.add('show');

        // For some crazy reason it only renders this properly on the third file creation.
        const myImage1 = await toPng(topTilesRef.current, { 
            filter,
            backgroundColor: 'black'
        });

        const myImage2 = await toPng(topTilesRef.current, { 
            filter,
            backgroundColor: 'black'
        });

        const myImage3 = await toPng(topTilesRef.current, { 
            filter,
            backgroundColor: 'black'
        });

        topTilesRef.current.classList.remove('show');

        download(myImage3, 'WhatsYourList.png');

        setIsSharing(false);
        handleClose();
    }

    const onSaveText = () => {
        let listText = 'WhatsYourList.com\n\n';

        favoriteItems.forEach((item, i) => {
            listText += `${i+1}. ${item.Title}\n`
        })

        downloadToFile(listText, 'WhatsYourList.txt', 'text/plain');

        handleClose();
    }

    const onShareLink = () => {
        setIsModalOpen(true);

        handleClose();
    }

    const onCloseModal = () => {
        setIsModalOpen(false);
    }

    return (
        <>
            <Button 
                className={`share-button ${isDisabledOrSharing ? 'disabled' : ''}`} 
                color='secondary'
                variant='outlined'
                onClick={handleShare}
                disabled={isDisabledOrSharing}
            >
                <IosShareIcon />
                {isSharing
                    ? 'Loading...'
                    : 'Share list'
                }
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={onShareLink}>
                    <b>Share link</b>
                </MenuItem>
                <MenuItem onClick={onSaveImage}>
                    Download image
                </MenuItem>
                <MenuItem onClick={onSaveText}>
                    Download text
                </MenuItem>
            </Menu>
            <Modal className='share-modal' isOpen={isModalOpen} onCloseModal={onCloseModal} modalTitle='Share list'>
                <div className='modal-content'>
                    <TextField 
                        value={shareLink}
                        variant='outlined'
                        color='secondary'
                    />
                </div>
                <div className='copy-message'>Copied to clipboard!</div>
            </Modal>
        </>
    );
}));

const ConnectedShareButton = forwardRef((_, topTilesRef) => {
    const { state, actions } = useContext(AppContext);
    const { favoriteMovies, favoriteSeries, user, currentMediaType } = state;
    const { uid } = user;
    const isOnline = getIsOnline();

    const { setIsShareButtonMounted, setGenericToastMessage } = actions;

    const type = currentMediaType === MEDIA_TYPE.Series
        ? 'series'
        : 'movies'

    const favoriteItems = currentMediaType === MEDIA_TYPE.Series
        ? favoriteSeries
        : favoriteMovies;

    return <ShareButton 
        ref={topTilesRef}
        favoriteItems={favoriteItems} 
        uid={uid} 
        type={type}
        isDisabled={!isOnline}
        setIsShareButtonMounted={setIsShareButtonMounted}
        setGenericToastMessage={setGenericToastMessage}
    />;
});

export default ConnectedShareButton;