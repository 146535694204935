import React, { memo, useContext, useEffect } from 'react';
import AppContext from '../../../AppContext';
import CustomTabs from '../../../CommonComponents/CustomTabs';
import { MovieListIcon, SeriesListIcon } from '../../../CommonComponents/Icons';
import { MEDIA_TYPE } from '../../../Constants';
import './MediaTypeTabs.scss';

const MediaTypeTabs = memo(({ changeMediaType, initialTabIndex, setIsSeriesTabHeaderMounted }) => {
    useEffect(() => {
        setIsSeriesTabHeaderMounted(true);

        return () => {
            setIsSeriesTabHeaderMounted(false);
        }
    }, [setIsSeriesTabHeaderMounted]);

    const tabConfigs = [
        {
            label: (
                <div className='tab-wrapper movies-tab-header'>
                    {MovieListIcon}
                    <span className='tab-name'>
                        Movies
                    </span>
                </div>
            ),
            onTabSelected: () => changeMediaType(MEDIA_TYPE.Movies)
        },
        {
            label: (
                <div className='tab-wrapper series-tab-header'>
                    {SeriesListIcon} 
                    <span className='tab-name'>
                        Series
                    </span>
                </div>
            ),
            onTabSelected: () => changeMediaType(MEDIA_TYPE.Series)
        }
    ];

    return <CustomTabs 
        className='media-type-tabs'
        initialTabIndex={initialTabIndex}
        tabConfigs={tabConfigs}
    />;
});

export default function ConnectedMediaTypeTabs({ initialTabIndex }) {
    const { actions } = useContext(AppContext);
    const { changeMediaType, setIsSeriesTabHeaderMounted } = actions;

    return (
        <MediaTypeTabs 
            initialTabIndex={initialTabIndex}
            changeMediaType={changeMediaType}
            setIsSeriesTabHeaderMounted={setIsSeriesTabHeaderMounted}
        />
    );
}