/* eslint-disable */
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useState, useEffect, useCallback, memo, forwardRef } from 'react';
import TextField from '@material-ui/core/TextField';
import debounce from 'lodash.debounce';
import { searchMediaApi } from '../../../Utilities/ApiUtilities';
import { useContext } from 'react';
import AppContext from '../../../AppContext';
import './SearchBar.scss';
import { LIST_TYPE, MAX_NUM_MOVIES, MEDIA_TYPE } from '../../../Constants';
import Chip from '../../../CommonComponents/Chip';
import { getIsOnline } from '../../../Utilities/EnvironmentUtilities';
import SearchFilter from './SearchFilter';

const SearchBar = memo(forwardRef(({ 
    addItemToList, 
    favoriteItems = [], 
    watchLaterItems = [],
    currentMediaType,
    currentListType,
    setIsSearchMounted,
    isOnline 
}, inputRef) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [reactKey, setReactKey] = useState(0);
    const [error, setError] = useState(null);
    const favoriteIds = favoriteItems.map(m => m.imdbID);
    const watchLaterIds = watchLaterItems.map(m => m.imdbID);

    const maxNum = MAX_NUM_MOVIES;
    const isAtMax = currentListType === LIST_TYPE.Favorites
        ? favoriteIds.length >= maxNum
        : watchLaterIds.length >= maxNum;

    const onChooseMovie = (e, value) => {
        addItemToList(value, currentMediaType, currentListType, "Search");
        setReactKey(reactKey+1);
        setInputValue('');
        setOptions([]);
    };

    const onInputChange = (e, value) => {
        setInputValue(value);
    };

    // https://stackoverflow.com/questions/40811535/delay-suggestion-time-material-ui-autocomplete-component
    const getOptionsDelayed = useCallback(
        debounce((text, callback) => {
            setOptions([]);
            searchMediaApi(text, currentMediaType, callback);
        }, 500),
        [currentMediaType]
    );

    useEffect(() => {
        getOptionsDelayed(inputValue, (results) => {
            if (results) {
                setError(null);
                setOptions(results);
            } else {
                setError('Unable to find results');
                setOptions([]);
            }
        });
    }, [inputValue, getOptionsDelayed]);

    // Tell guide search is mounted
    useEffect(() => {
        setIsSearchMounted(true);

        return () => {
            setIsSearchMounted(false);
        }
    }, [setIsSearchMounted])

    const renderOption = ({ Title, Year, Poster, imdbID }) => {
        return (
            <div className='search-option'>
                <img className='poster' src={Poster} />
                <div className='title'>{`${Title} (${Year})`}</div>
                <Chip 
                    favoriteIds={favoriteIds}
                    watchLaterIds={watchLaterIds}
                    imdbID={imdbID}
                />
            </div>
        );
    };

    const loading = (options.length === 0) && (inputValue !== '') && !error

    const filterOptions = (options) => {
        const retOptions = [];

        options.forEach(o => {
            const i = retOptions.findIndex(ro => ro.imdbID === o.imdbID);

            if ((i === -1)) {
                retOptions.push(o);
            }
        })

        return retOptions;
    }

    const getOptionLabel = ({ Title, Year }) => {
        return `${Title} (${Year})`;
    }

    const getOptionDisabled = ({ imdbID }) => {
        return (favoriteIds.some(id => id === imdbID))
            || (watchLaterIds.some(id => id === imdbID));
    }

    const renderInput = (params) => {
        let placeholder;
        if (isAtMax) {
            placeholder = `Max added (${maxNum})`;
        } else if (!isOnline) {
            placeholder = 'You are currently offline.';
        } else {
            placeholder = 'Search';
        }

        return (
            <TextField 
                {...params} 
                inputRef={inputRef}
                className='search-text-field'
                autoFocus={reactKey > 0} // After a movie is chosen and this is rerendered, maintain focus to easily add more movies
                placeholder={placeholder} 
                color="secondary" 
                variant="outlined" 
                margin="dense"
            />
        );
    }

    const noOptionsText = error || 'Search by title';

    return (
        <div className='search-wrapper'>
            <SearchFilter />
            <Autocomplete 
                key={reactKey}
                className='search'
                disabled={isAtMax || !isOnline}
                multiple={false}
                options={options}
                onChange={onChooseMovie}
                onInputChange={onInputChange}
                loading={loading}
                noOptionsText={noOptionsText}
                filterOptions={filterOptions}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                renderOption={renderOption}
                renderInput={renderInput}
            />
        </div>
    );
}));

const ConnectedSearch = forwardRef((_, inputRef) => {
    const { state, actions } = useContext(AppContext);
    const { favoriteMovies, watchLaterMovies, favoriteSeries, watchLaterSeries, currentMediaType, currentListType } = state;
    const { addItemToList, setIsSearchMounted } = actions;

    const isOnline = getIsOnline();

    let favoriteItems, watchLaterItems;
    if (currentMediaType === MEDIA_TYPE.Movies) {
        favoriteItems = favoriteMovies;
        watchLaterItems = watchLaterMovies;
    } else {
        favoriteItems = favoriteSeries;
        watchLaterItems = watchLaterSeries;
    }

    return (
        <SearchBar 
            ref={inputRef}
            favoriteItems={favoriteItems} 
            watchLaterItems={watchLaterItems}
            addItemToList={addItemToList} 
            currentMediaType={currentMediaType}
            currentListType={currentListType}
            setIsSearchMounted={setIsSearchMounted}
            isOnline={isOnline}
        />
    );
});

export default ConnectedSearch;