import { LOCAL_STORAGE_PWA_CHROME_POPUP, LOCAL_STORAGE_PWA_SAFARI_POPUP } from "../Constants";
import { dateDiffInDays } from "./CommonUtilities";

function getIsIos() {
    const ua = window.navigator.userAgent;

    return /iPad|iPhone|iPod/.test(ua) && !window.MSStream;
}

function getIsSafari() {
    const ua = window.navigator.userAgent;

    return !!ua.match(/WebKit/i);
}

export function getIsChromeIos() {
    const ua = window.navigator.userAgent;
    const isIos = getIsIos();

    return isIos && !!ua.match(/CriOS/i);
}

export function getIsSafariIos() {
    const isIos = getIsIos();
    const isSafari = getIsSafari();
    const isChromeIos = getIsChromeIos();
    
    return isIos && isSafari && !isChromeIos;
}

export function getIsStandalone() {
    return window.matchMedia('(display-mode: standalone)').matches;
}

export function getIsOnline() {
    return window.navigator.onLine;
}

function getIsExpired(nowString, popupTimeString) {
    const nowDate = new Date(parseInt(nowString));

    const popupTime = parseInt(popupTimeString);
    if (isNaN(popupTime)) {
        return true;
    }

    const popupDate = new Date(popupTime);

    return dateDiffInDays(popupDate, nowDate) >= 1;
}

export function getCanShowSafariPwaPopup(nowString) {
    const popupTimeString = localStorage.getItem(LOCAL_STORAGE_PWA_SAFARI_POPUP);

    const isPopupExpired = getIsExpired(nowString, popupTimeString);
    const isSafariIos = getIsSafariIos();
    const isStandalone = getIsStandalone();

    return isPopupExpired && isSafariIos && !isStandalone;
}

export function getCanShowChromePwaPopup(nowString) {
    const popupTimeString = localStorage.getItem(LOCAL_STORAGE_PWA_CHROME_POPUP);
    const isPopupExpired = getIsExpired(nowString, popupTimeString);
    const isChromeIos = getIsChromeIos();
    const isStandalone = getIsStandalone();

    return isPopupExpired && isChromeIos && !isStandalone;
}