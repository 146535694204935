import './Suggestions.scss';
import { memo, useContext, useState, useEffect } from 'react';
import AppContext from '../../../AppContext';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { smallScreenMax } from '../../../StyleExports.module.scss';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import { FirebaseContext } from '../../../Firebase';
import { LIST_TYPE, MEDIA_TYPE } from '../../../Constants';
import { getCollectionName } from '../../../Utilities/CommonUtilities';

const Suggestions = memo(({ 
    favoriteItems, 
    watchLaterItems,
    friends, 
    suggestedItems, 
    setSuggestedItems, 
    addItemToList,
    currentMediaType,
    currentListType
}) => {
    const firebase = useContext(FirebaseContext);
    const [isLoading, setIsLoading] = useState(false);
    const [numTries, setNumTries] = useState(0);
    const [error, setError] = useState(null);

    const onTryAgain = () => {
        setError(null);
        setNumTries(0);
    }

    useEffect(() => {
        if (isLoading) { return }
        if (suggestedItems.length > 0) { return }
        
        if (friends.length === numTries) { 
            setError('No items found');
            return 
        }

        if (numTries > 2) {
            setError('Unable to load');
            return;
        }

        setIsLoading(true);

        // Choose a random friend
        const randomIndex = getRandomInt(0, friends.length);
        const friend = friends[randomIndex];
        const listName = getCollectionName(currentMediaType, LIST_TYPE.Favorites);

        // Get their list
        firebase.firestore()
            .collection('publicUserInfo')
            .doc(friend.uid)
            .collection(listName)
            .get()
            .then(querySnapshot => {
                if (querySnapshot.size > 0) {
                    const randomDocs = querySnapshot.docs.sort(() => Math.random() - Math.random()).slice(0, 10);
                    const items = randomDocs.map(d => d.data()).filter(m => {
                        if (m.Poster === 'N/A') {
                            return false;
                        }

                        if (favoriteItems.findIndex(f =>  f.imdbID === m.imdbID) > -1) {
                            return false;
                        }

                        if (watchLaterItems.findIndex(f =>  f.imdbID === m.imdbID) > -1) {
                            return false;
                        }
            
                        return true;
                    });

                    if (items.length > 0) {
                        setSuggestedItems(items, currentMediaType);
                    } else {
                        setNumTries(numTries + 1);
                        setIsLoading(false);
                    }
                } else {
                    setNumTries(numTries + 1);
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setError('Unable to load');
            });
    }, [
        favoriteItems, 
        watchLaterItems,
        friends, 
        setIsLoading, 
        setSuggestedItems, 
        firebase, 
        isLoading, 
        suggestedItems, 
        setNumTries, 
        setError, 
        numTries,
        currentMediaType
    ])

    useEffect(() => {
        if (suggestedItems.length) {
            setNumTries(0);
            setIsLoading(false);
        }
    }, [setIsLoading, suggestedItems, setNumTries]);

    useEffect(() => {
        // This handles when favorite items isn't loaded yet on first render
        if (favoriteItems.length && suggestedItems.length) {
            const filteredSuggestions = suggestedItems.filter(m => {
                return favoriteItems.findIndex(f => {
                    return f.imdbID === m.imdbID;
                }) === -1;
            });

            if (filteredSuggestions.length < suggestedItems.length) {
                setSuggestedItems(filteredSuggestions, currentMediaType);
            }
        }
    }, [suggestedItems, favoriteItems, setSuggestedItems, currentMediaType])

    if (isLoading) {
        return <div className='suggestions'>
            <h1 className='section-title'>Friends' Favorites</h1>
            <div className='suggestions-overlay'>
                <CircularProgress color="secondary" />
            </div>
        </div>;
    }

    if (error) {
        return <div className='suggestions'>
            <h1 className='section-title'>Friends' Favorites</h1>
            <div className='suggestions-overlay'>
                {error}
                <Button className="try-again-button" variant="contained" onClick={onTryAgain}>
                    Try again
                </Button>
            </div>
        </div>
    }

    const responsive = {
        largeScreen: {
            breakpoint: { max: 3000, min: parseInt(smallScreenMax) },
            items: 4
        },
        smallScreen: {
            breakpoint: { max: parseInt(smallScreenMax), min: parseInt(smallScreenMax) },
            items: 3
        }
    };

    return (
        <div className='suggestions'>
            <h1 className='section-title'>Friends' Favorites</h1>
            <Carousel 
                className='carousel' 
                responsive={responsive} 
                infinite={true}
                shouldResetAutoplay={false} // otherwise just starts autoplay on click
            >
                {suggestedItems.map(item => (
                    <div className="suggestion" key={item.imdbID} onClick={() => addItemToList(item, currentMediaType, currentListType)}>
                        <img 
                            className='poster' 
                            src={item.Poster} 
                            alt='poster' />
                        <div className='hover-overlay'>
                            <AddCircleIcon className='add-icon' />
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
});

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export default function ConnectedSuggestions() {
    const { state, actions } = useContext(AppContext);
    const { suggestedMovies, friends, favoriteMovies, watchLaterMovies, currentMediaType, currentListType, favoriteSeries, watchLaterSeries, suggestedSeries } = state;
    const { setSuggestedItems, addItemToList } = actions;

    let favoriteItems, watchLaterItems, suggestedItems;
    if (currentMediaType === MEDIA_TYPE.Movies) {
        favoriteItems = favoriteMovies;
        watchLaterItems = watchLaterMovies;
        suggestedItems = suggestedMovies;
    } else {
        favoriteItems = favoriteSeries;
        watchLaterItems = watchLaterSeries;
        suggestedItems = suggestedSeries;
    }

    return <Suggestions 
        friends={friends}
        favoriteItems={favoriteItems}
        watchLaterItems={watchLaterItems}
        suggestedItems={suggestedItems} 
        setSuggestedItems={setSuggestedItems} 
        addItemToList={addItemToList}
        currentMediaType={currentMediaType}
        currentListType={currentListType}
    />;
}