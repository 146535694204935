import { memo, useMemo } from 'react';
import { BarChart, Bar, YAxis, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import './Charts.scss';
import { useContext } from 'react';
import AppContext from '../../../AppContext';
import { colorYellow } from '../../../StyleExports.module.scss';
import { LIST_TYPE, MEDIA_TYPE } from '../../../Constants';

const Charts = memo(({ favoriteItems = [], areChartsDisabled, currentMediaType }) => {
    const genreData = useMemo(() => {
        const genresDict = {};

        favoriteItems.forEach((item, i) => {
            const { Genres, Title } = item;
            const scoreToAdd = 100 - i;

            Genres.forEach(genre => {
                if (!genresDict[genre]) {
                    genresDict[genre] = {
                        score: 0,
                        titles: []
                    };
                }

                const barInfo = genresDict[genre];

                barInfo.score += scoreToAdd;
                barInfo.titles.push(`#${i+1} ${Title}`);
            });
        });

        return Object.keys(genresDict).map(genre => ({
            name: genre,
            score: genresDict[genre].score,
            titles: genresDict[genre].titles
        })).sort((a, b) => b.score - a.score).slice(0, 10);
    }, [favoriteItems]);

    const decadeData = useMemo(() => {
        const decadesDict = {};

        favoriteItems.forEach((item, i) => {
            const { Year, Title } = item;
            const scoreToAdd = 100 - i;
            const startYear = Year.slice(0, 4); // For series, Year will be a range
            const decade = Math.floor(startYear/10)*10;

            if (!decadesDict[decade]) {
                decadesDict[decade] = {
                    score: 0,
                    titles: []
                };
            }

            const barInfo = decadesDict[decade];

            barInfo.score += scoreToAdd;
            barInfo.titles.push(`#${i+1} ${Title}`);
        });

        return Object.keys(decadesDict).map(decade => ({
            name: `${decade}s`,
            decade,
            score: decadesDict[decade].score,
            titles: decadesDict[decade].titles
        })).sort((a, b) => a.decade - b.decade);
    }, [favoriteItems]);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const displayTitles = payload[0].payload.titles.slice(0, 5);
            const numAdditionalTitles = payload[0].payload.titles.length - 5;
            const mediaName = currentMediaType === MEDIA_TYPE.Movies
                ? 'movies'
                : 'series';

            return (
                <div className="custom-tooltip">
                    <div className='genre-name'>{payload[0].payload.name}</div>
                    {displayTitles.map((title, i) =>
                        <div key={`${title}_${i}`}>{title}</div>
                    )}
                    {numAdditionalTitles === 1 && currentMediaType === MEDIA_TYPE.Movies &&
                        <div>And 1 additional movie</div>
                    }
                    {numAdditionalTitles > 1 &&
                        <div>And {numAdditionalTitles} additional {mediaName}</div>
                    }
                </div>
            );
        }
      
        return null;
    };

    if (!favoriteItems.length) {
        return null;
    }

    // Hardcoding axis width to center (https://github.com/recharts/recharts/issues/843)
    return (
        <div className={`charts ${areChartsDisabled ? 'disabled' : ''}`}>
            <ResponsiveContainer height="59%" className='genre-chart'>
                <BarChart data={genreData} layout='vertical'>
                    <YAxis dataKey="name" type="category" interval={0} tick={{ fill: 'black' }} width={100} />
                    <XAxis type="number" tick={false} height={0} />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="score" fill={colorYellow} stroke="#000000" />
                </BarChart>
            </ResponsiveContainer>
            <ResponsiveContainer height="40%">
                <BarChart data={decadeData} layout='vertical'>
                    <YAxis dataKey="name" type="category" interval={0} tick={{ fill: 'black' }} width={100} />
                    <XAxis type="number" tick={false} height={0} />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="score" fill={colorYellow} stroke="#000000" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
});

export default function ConnectedCharts({ viewListItems }) {
    const { state } = useContext(AppContext);
    const { favoriteMovies, favoriteSeries, currentMediaType, currentListType } = state;

    // If passed items from current viewList, use those
    const favoriteItems = currentMediaType === MEDIA_TYPE.Movies
        ? favoriteMovies
        : favoriteSeries;

    const items = viewListItems || favoriteItems;

    const areChartsDisabled = viewListItems
        ? false
        : currentListType === LIST_TYPE.WatchLater;

    return <Charts 
        favoriteItems={items} 
        currentMediaType={currentMediaType}
        areChartsDisabled={areChartsDisabled}
    />;
}